/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");






::-webkit-scrollbar {
    display: none;
}




:root {
  --primary-color: #495bfd;
  --secondary-color: #059669;
  --background-color: #080808;
  --text-light: #cbd5e1;
  --white: #ffffff;
  --max-width: 1200px;
}

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.section__container {
  max-width: var(--max-width);
  margin: auto;
  padding: 5rem 1rem;
}

.section__subheader {
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  color: var(--secondary-color);
  text-align: center;
}

.section__header {

  
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 3rem;
  text-align: center;
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(#c2b3ea),
    color-stop(#99b8f1),
    color-stop(#6abceb),
    color-stop(#41bed8),
    color-stop(#3abdbb),
    color-stop(#44c1ac),
    color-stop(#59c39a),
    color-stop(#72c486),
    color-stop(#83cd7f),
    color-stop(#96d676),
    color-stop(#acde6c),
    to(#c5e562)
  );
  background: -o-linear-gradient(
    left,
    #c2b3ea,
    #99b8f1,
    #6abceb,
    #41bed8,
    #3abdbb,
    #44c1ac,
    #59c39a,
    #72c486,
    #83cd7f,
    #96d676,
    #acde6c,
    #c5e562
  );
  background: linear-gradient(
    to right,
    #c2b3ea,
    #99b8f1,
    #6abceb,
    #41bed8,
    #3abdbb,
    #44c1ac,
    #59c39a,
    #72c486,
    #83cd7f,
    #96d676,
    #acde6c,
    #c5e562
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn {
  padding: 0.75rem 2rem;
  outline: none;
  border: none;
  font-size: 1rem;
  color: var(--white);
  background-color: var(--primary-color);
  border-radius: 5px;
  cursor: pointer;
}

a {
  text-decoration: none;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: #000000;
}

.nav__container {
  padding: 2rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 1rem;
  border-bottom: 1px solid var(--text-light);
}

.nav__logo {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--white);
}

.nav__links {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 2rem;
}

.link a {
  padding: 0 0.5rem;
  color: var(--text-light);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.link a:hover {
  color: var(--white);
}

.header__container {
  padding-top: 10rem;
  text-align: center;
}

.header__container .section__header {
  font-size: 5rem;
  font-weight: 700;
  line-height: 6rem;
}

.header__container .section__header span {
  -webkit-text-stroke: 1px var(--text-light);
  -webkit-text-fill-color: var(--background-color);
}

.header__container p {
  max-width: 900px;
  margin: 3rem auto 3rem;
  color: var(--text-light);
}

.service__grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 2rem 1fr 2rem 1fr;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 4rem;
}

.service__card {
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
}

.service__icon {
  margin-bottom: 1rem;
  height: 50px;
  width: 50px;
  display: -ms-grid;
  display: grid;
  place-content: center;
  font-size: 1.5rem;
  color: var(--white);
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
}

.service__icon img {
  max-width: 30px;
}

.service__card h4 {
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--white);
}

.service__card p {
  color: var(--text-light);
  margin-bottom: 1rem;
}

.service__card a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
  font-size: 0.8rem;
  color: var(--text-light);
}

.service__card a span {
  font-size: 1rem;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.service__card a:hover span {
  -webkit-transform: translateX(5px);
      -ms-transform: translateX(5px);
          transform: translateX(5px);
}

.template__container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 2rem 1fr;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.template__container .section__subheader {
  text-align: left;
}

.template__container .section__header {
  margin-bottom: 1rem;
  text-align: left;
}

.template__container .description {
  color: var(--text-light);
  margin-bottom: 2rem;
}

.template__image {
  text-align: center;
}

.template__image img {
  width: 100%;
  max-width: 400px;
  border-radius: 1rem;
}

.client__grid {
  margin-top: 4rem;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 2rem 1fr 2rem 1fr;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.client__card {
  padding: 2.5rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
}

.client__card img {
  margin-bottom: 1rem;
  max-width: 60px;
  border: 2px solid var(--white);
  border-radius: 100%;
}

.client__card h4 {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--white);
}

.client__card p {
  font-size: 1rem;
  color: var(--text-light);
}

.question__accordian {
  max-width: 900px;
  margin: 4rem auto 0;
  display: -ms-grid;
  display: grid;
  gap: 1rem;
}

.question__box {
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
  color: var(--text-light);
}

.question__box .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 1rem;
  font-weight: 500;
  color: var(--white);
}

.question__box .title .icon {
  padding: 5px 10px;
  font-size: 1.2rem;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.question__box .content {
  height: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  overflow: hidden;
}

.question__box.active .content {
  height: 90px;
  padding-top: 1rem;
}

.footer__grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 2fr 2rem 1fr 2rem 1fr 2rem 1fr;
  grid-template-columns: 2fr repeat(3, 1fr);
  gap: 2rem;
}

.footer__col h4 {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--white);
}

.footer__col p {
  color: var(--text-light);
  margin-bottom: 1rem;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.footer__col p:hover {
  color: var(--white);
}

.footer__col p span {
  margin-right: 0.5rem;
  color: var(--primary-color);
}

.footer__bar {
  padding: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 1rem;
  border-top: 1px solid var(--text-light);
}

.footer__bar p {
  font-size: 0.9rem;
  color: var(--text-light);
}

.socials {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}

.socials span {
  padding: 5px 10px;
  border-radius: 5px;
  color: var(--text-light);
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.socials span:hover {
  color: var(--white);
}

@media (width < 900px) {
  .nav__links {
    display: none;
  }

  .header__container {
    padding-top: 5rem;
  }

  .service__grid {
    -ms-grid-columns: 1fr 1rem 1fr;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .template__container {
    -ms-grid-columns: (1fr)[1];
    grid-template-columns: repeat(1, 1fr);
  }

  .template__content,
  .template__content :is(.section__subheader, .section__header) {
    text-align: center;
  }

  .template__image {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-area: 1/1/2/0;
  }

  .client__grid {
    -ms-grid-columns: 1fr 1rem 1fr;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .question__box.active .content {
    height: 135px;
  }
}

@media (width < 600px) {
  .header__container .section__header {
    font-size: 3rem;
    line-height: 5rem;
  }

  .service__grid {
    -ms-grid-columns: (1fr)[1];
    grid-template-columns: repeat(1, 1fr);
  }

  .client__grid {
    -ms-grid-columns: (1fr)[1];
    grid-template-columns: repeat(1, 1fr);
  }

  .question__box.active .content {
    height: 180px;
  }

  .footer__grid {
    -ms-grid-columns: (1fr)[2];
    grid-template-columns: repeat(2, 1fr);
  }


  .footer__grid {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    justify-items: center;
  }
  .footer__bar {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
  }
}


.service__card {
  position: relative;
  overflow: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  /* Include border in width and height calculations */
  -webkit-transition: border 0.5s ease-in-out;
  -o-transition: border 0.5s ease-in-out;
  transition: border 0.5s ease-in-out;
  /* Increase transition duration for a smoother effect */
  will-change: border;
  /* Optimize performance by letting the browser know the border will change */
}

.service__card:hover {
  border: 6px solid rgb(6, 255, 218);
}

.button {
  position: relative;
  padding: 10px 22px;
  border-radius: 6px;
  border: none;
  color: #fff;
  cursor: pointer;
  background-color: #7d2ae8;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.button:active {
  -webkit-transform: scale(0.96);
      -ms-transform: scale(0.96);
          transform: scale(0.96);
}

.button:before,
.button:after {
  position: absolute;
  content: "";
  width: 150%;
  left: 50%;
  height: 100%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: -1000;
  background-repeat: no-repeat;
}

.button:hover:before {
  top: -70%;
  background-image: -o-radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    -o-radial-gradient(circle, transparent 20%, #7d2ae8 20%, transparent 30%),
    -o-radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    -o-radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    -o-radial-gradient(circle, transparent 10%, #7d2ae8 15%, transparent 20%),
    -o-radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    -o-radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    -o-radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    -o-radial-gradient(circle, #7d2ae8 20%, transparent 20%);
  background-image: radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    radial-gradient(circle, transparent 20%, #7d2ae8 20%, transparent 30%),
    radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, #7d2ae8 15%, transparent 20%),
    radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    radial-gradient(circle, #7d2ae8 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%,
    10% 10%, 18% 18%;
  background-position: 50% 120%;
  -webkit-animation: greentopBubbles 0.6s ease;
          animation: greentopBubbles 0.6s ease;
}

@-webkit-keyframes greentopBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%,
      40% 90%, 55% 90%, 70% 90%;
  }

  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%,
      50% 50%, 65% 20%, 90% 30%;
  }

  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%,
      50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

@keyframes greentopBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%,
      40% 90%, 55% 90%, 70% 90%;
  }

  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%,
      50% 50%, 65% 20%, 90% 30%;
  }

  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%,
      50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

.button:hover::after {
  bottom: -70%;
  background-image: -o-radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    -o-radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    -o-radial-gradient(circle, transparent 10%, #7d2ae8 15%, transparent 20%),
    -o-radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    -o-radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    -o-radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    -o-radial-gradient(circle, #7d2ae8 20%, transparent 20%);
  background-image: radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, #7d2ae8 15%, transparent 20%),
    radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    radial-gradient(circle, #7d2ae8 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 20% 20%, 18% 18%;
  background-position: 50% 0%;
  -webkit-animation: greenbottomBubbles 0.6s ease;
          animation: greenbottomBubbles 0.6s ease;
}

@-webkit-keyframes greenbottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%,
      70% -10%, 70% 0%;
  }

  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%,
      105% 0%;
  }

  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%,
      110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

@keyframes greenbottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%,
      70% -10%, 70% 0%;
  }

  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%,
      105% 0%;
  }

  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%,
      110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

















.main_back {
  position: absolute;
  border-radius: 10px;
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
  width: 11em;
  height: 11em;
  background: -webkit-gradient(linear, right top, left top, from(#03a9f4), color-stop(#cc39a4), to(#ffb5d2));
  background: -o-linear-gradient(right, #03a9f4, #cc39a4, #ffb5d2);
  background: linear-gradient(270deg, #03a9f4, #cc39a4, #ffb5d2);
  z-index: -2;
  -webkit-box-shadow: inset 0px 0px 180px 5px #ffffff;
          box-shadow: inset 0px 0px 180px 5px #ffffff;
}

.main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 14em;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: -1;
}

.card {
  width: 60px;
  height: 60px;
  border-top-left-radius: 10px;
  background: lightgrey;
  -webkit-transition: .4s ease-in-out, .2s background-color ease-in-out, .2s background-image ease-in-out;
  -o-transition: .4s ease-in-out, .2s background-color ease-in-out, .2s background-image ease-in-out;
  transition: .4s ease-in-out, .2s background-color ease-in-out, .2s background-image ease-in-out;
  background: rgba(255, 255, 255, 0.596);
  backdrop-filter: blur(5px);
  border: 1px solid transparent;
  -webkit-backdrop-filter: blur(5px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.card .instagram {
  opacity: 0;
  -webkit-transition: .2s ease-in-out;
  -o-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  fill: #cc39a4;
}

.card:nth-child(2) {
  border-radius: 0px;
}

.card:nth-child(2) .twitter {
  opacity: 0;
  -webkit-transition: .2s ease-in-out;
  -o-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  fill: #03A9F4;
}

.card:nth-child(3) {
  border-top-right-radius: 10px;
  border-top-left-radius: 0px;
}

.card:nth-child(3) .dribble {
  opacity: 0;
  -webkit-transition: .2s ease-in-out;
  -o-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  fill: #ffb5d2;
}

.card:nth-child(4) {
  border-radius: 0px;
}

.card:nth-child(4) .codepen {
  opacity: 0;
  -webkit-transition: .2s ease-in-out;
  -o-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  fill: black;
}

.card:nth-child(5) {
  border-radius: 0px;
}

.card:nth-child(5) .uiverse {
  position: absolute;
  margin-left: 0.2em;
  margin-top: 0.2em;
  opacity: 0;
  -webkit-transition: .2s ease-in-out;
  -o-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.card:nth-child(6) {
  border-radius: 0px;
}

.card:nth-child(6) .discord {
  opacity: 0;
  -webkit-transition: .2s ease-in-out;
  -o-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  fill: #8c9eff;
}

.card:nth-child(7) {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 0px;
}

.card:nth-child(7) .github {
  opacity: 0;
  -webkit-transition: .2s ease-in-out;
  -o-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  fill: black;
}

.card:nth-child(8) {
  border-radius: 0px;
}

.card:nth-child(8) .telegram {
  opacity: 0;
  -webkit-transition: .2s ease-in-out;
  -o-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  fill: #29b6f6;
}

.card:nth-child(9) {
  border-bottom-right-radius: 10px;
  border-top-left-radius: 0px;
}

.card:nth-child(9) .reddit {
  opacity: 0;
  -webkit-transition: .2s ease-in-out;
  -o-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.main:hover {
  width: 14em;
  cursor: pointer;
}

.main:hover .main_back {
  opacity: 0;
}

.main:hover .card {
  margin: .2em;
  border-radius: 10px;
  -webkit-box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.2);
}

.main:hover .card:nth-child(5) {
  border: transparent;
}

.main:hover .text {
  opacity: 0;
  z-index: -3;
}

.main:hover .instagram {
  opacity: 1;
}

.main:hover .twitter {
  opacity: 1;
}

.main:hover .dribble {
  opacity: 1;
}

.main:hover .codepen {
  opacity: 1;
}

.main:hover .uiverse {
  opacity: 1;
}

.main:hover .discord {
  opacity: 1;
}

.main:hover .github {
  opacity: 1;
}

.main:hover .telegram {
  opacity: 1;
}

.main:hover .reddit {
  opacity: 1;
}

.card:nth-child(1):hover {
  background-color: #000000;
}

.card:nth-child(1):hover .instagram {
  fill: white;
}

.card:nth-child(2):hover {
  background-color: #03A9F4;
}

.card:nth-child(2):hover .twitter {
  fill: white;
}

.card:nth-child(3):hover {
  background-color: #ffb5d2;
}

.card:nth-child(3):hover .dribble {
  fill: white;
}

.card:nth-child(4):hover {
  background-color: #1E1F26;
}

.card:nth-child(4):hover .codepen {
  fill: white;
}

.card:nth-child(5):hover {
  -webkit-animation: backgroundIMG .1s;
          animation: backgroundIMG .1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.card:nth-child(5):hover .uiverse #paint0_linear_501_142 stop {
  stop-color: white;
}

.card:nth-child(5):hover .uiverse #paint1_linear_501_142 stop {
  stop-color: white;
}

.card:nth-child(5):hover .uiverse #paint2_linear_501_142 stop {
  stop-color: white;
}

@-webkit-keyframes backgroundIMG {
  100% {
    background-image: -webkit-gradient(linear,left top, left bottom,from(#BF66FF),color-stop(#6248FF),to(#00DDEB));
    background-image: linear-gradient(#BF66FF,#6248FF,#00DDEB);
  }
}

@keyframes backgroundIMG {
  100% {
    background-image: -webkit-gradient(linear,left top, left bottom,from(#BF66FF),color-stop(#6248FF),to(#00DDEB));
    background-image: -o-linear-gradient(#BF66FF,#6248FF,#00DDEB);
    background-image: linear-gradient(#BF66FF,#6248FF,#00DDEB);
  }
}

.card:nth-child(6):hover {
  background-color: #8c9eff;
}

.card:nth-child(6):hover .discord {
  fill: white;
}

.card:nth-child(7):hover {
  background-color: black;
}

.card:nth-child(7):hover .github {
  fill: white;
}

.card:nth-child(8):hover {
  background-color: #29b6f6;
}

.card:nth-child(8):hover .telegram > path:nth-of-type(1) {
  fill: white;
}

.card:nth-child(8):hover .telegram > path:nth-of-type(2) {
  fill: #29b6f6;
}

.card:nth-child(8):hover .telegram > path:nth-of-type(3) {
  fill: #29b6f6;
}

.card:nth-child(9):hover {
  background-color: rgb(255,69,0);
}

.card:nth-child(9) .reddit > g circle {
  fill: rgb(255,69,0);
}

.card:nth-child(9) .reddit > g path {
  fill: white;
}

.text {
  position: absolute;
  font-size: 0.7em;
  -webkit-transition: .4s ease-in-out;
  -o-transition: .4s ease-in-out;
  transition: .4s ease-in-out;
  color: black;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.33em;
  z-index: 3;
}

.social{

margin-left: 10px;
}









.form-container {
  width: 400px;
  margin-left: auto;
  margin-top: 15%;
  background: -o-linear-gradient(#212121, #212121) padding-box,
              -o-linear-gradient(305deg, transparent 35%, 
              #c2b3ea, #99b8f1, #6abceb, #41bed8, #3abdbb, 
              #44c1ac, #59c39a, #72c486, #83cd7f, #96d676, 
              #acde6c, #c5e562) border-box;
  background: linear-gradient(#212121, #212121) padding-box,
              linear-gradient(145deg, transparent 35%, 
              #c2b3ea, #99b8f1, #6abceb, #41bed8, #3abdbb, 
              #44c1ac, #59c39a, #72c486, #83cd7f, #96d676, 
              #acde6c, #c5e562) border-box;
  border: 2px solid transparent;
  padding: 32px 24px;
  font-size: 14px;
  font-family: inherit;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 16px;
  background-size: 200% 100%;
  -webkit-animation: gradient 5s ease infinite;
          animation: gradient 5s ease infinite;
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.form-container button:active {
  scale: 0.95;
}

.form-container .form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 20px;
}

.form-container .form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 2px;
}

.form-container .form-group label {
  display: block;
  margin-bottom: 5px;
  color: #717171;
  font-weight: 600;
  font-size: 12px;
}

.form-container .form-group input {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  color: #fff;
  font-family: inherit;
  background-color: transparent;
  border: 1px solid #414141;
}

.form-container .form-group textarea {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  resize: none;
  color: #fff;
  height: 96px;
  border: 1px solid #414141;
  background-color: transparent;
  font-family: inherit;
}

.form-container .form-group input::-webkit-input-placeholder {
  opacity: 0.5;
}

.form-container .form-group input::-moz-placeholder {
  opacity: 0.5;
}

.form-container .form-group input:-ms-input-placeholder {
  opacity: 0.5;
}

.form-container .form-group input::-ms-input-placeholder {
  opacity: 0.5;
}

.form-container .form-group input::placeholder {
  opacity: 0.5;
}

.form-container .form-group input:focus {
  outline: none;
  border-color: #1ffec6;
}

.form-container .form-group textarea:focus {
  outline: none;
  border-color: #1ffec6;
}

.form-container .form-submit-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-item-align: start;
      align-self: flex-start;
  font-family: inherit;
  color: #717171;
  font-weight: 600;
  width: 40%;
  background: #313131;
  border: 1px solid #414141;
  padding: 12px 16px;
  font-size: inherit;
  gap: 8px;
  margin-top: 8px;
  cursor: pointer;
  border-radius: 6px;
}

.form-container .form-submit-btn:hover {
  background-color: #fff;
  border-color: #fff;
}



.social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.description {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1; /* Take up available space */
  margin-right: 20px; /* Add some spacing between description and form */
}

.con{
  margin-top: 30%;
  color:#ffffff;
  font-size: 1.1rem;
}

/* Your existing styles */



/* Add a media query for screens with a maximum width of, for example, 600 pixels */
@media only screen and (max-width: 600px) {
  .description {
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
    text-align: center; /* Center-align the text */
    margin-bottom: 20px; /* Add some margin between the text and the form */
  }
}

  @media only screen and (max-width: 600px) {
    .social {
      text-align: center; /* Center-align the text */
      margin-bottom: 20px; /* Add some margin between the text and the form */
    }

  .form-container {
    width: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: absolute;
    right: -10px;
    margin-top: 334px;
  
  }
}





.question__box {
  -webkit-transition: background-color 0.3s ease-in-out;
  -o-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}

.question__box:hover {
  background-color: #000000; /* Add your preferred hover color */
}


.question__box {
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.question__box:hover {
  -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
          transform: scale(1.05); /* Adjust the scaling factor as needed */
}





.bounce {
  -webkit-animation: bounce 0.5s ease alternate;
          animation: bounce 0.5s ease alternate;
}

@-webkit-keyframes bounce {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

@keyframes bounce {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}





.card-container {
  width: 300px;
  height: 300px;
  position: relative;
  border-radius: 10px;
}

.card-container::before {
  content: "";
  z-index: -1;
  position: absolute;
  inset: 0;
  background:-webkit-gradient( linear, left top, right top, from(#c2b3ea), color-stop(#99b8f1), color-stop(#6abceb), color-stop(#41bed8), color-stop(#3abdbb), color-stop(#44c1ac), color-stop(#59c39a), color-stop(#72c486), color-stop(#83cd7f), color-stop(#96d676), color-stop(#acde6c), to(#c5e562) );
  background:-o-linear-gradient( left, #c2b3ea, #99b8f1, #6abceb, #41bed8, #3abdbb, #44c1ac, #59c39a, #72c486, #83cd7f, #96d676, #acde6c, #c5e562 );
  background:linear-gradient( to right, #c2b3ea, #99b8f1, #6abceb, #41bed8, #3abdbb, #44c1ac, #59c39a, #72c486, #83cd7f, #96d676, #acde6c, #c5e562 );
  -webkit-transform: translate3d(0, 0, 0) scale(0.95);
          transform: translate3d(0, 0, 0) scale(0.95);
  -webkit-filter: blur(20px);
          filter: blur(20px);
}

.card {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  overflow: hidden;
}

.card .img-content {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: -o-linear-gradient(135deg, #000000 0%, #000000 100% );
  background: linear-gradient(-45deg, #000000 0%, #000000 100% );
  -webkit-transition: scale 0.6s, rotate 0.6s, -webkit-filter 1s;
  transition: scale 0.6s, rotate 0.6s, -webkit-filter 1s;
  -o-transition: scale 0.6s, rotate 0.6s, filter 1s;
  transition: scale 0.6s, rotate 0.6s, filter 1s;
  transition: scale 0.6s, rotate 0.6s, filter 1s, -webkit-filter 1s;
}

.card .img-content svg {
  width: 50px;
  height: 50px;
  fill: #212121;
  -webkit-transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  -o-transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.card .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 10px;
  color: #e8e8e8;
  padding: 20px 24px;
  line-height: 1.5;
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(50px);
      -ms-transform: translateY(50px);
          transform: translateY(50px);
  -webkit-transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  -o-transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.card .content .heading {
  font-size: 32px;
  font-weight: 700;
}

.card:hover .content {
  opacity: 1;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
}

.card:hover .img-content {
  scale: 2.5;
  rotate: 30deg;
  -webkit-filter: blur(7px);
          filter: blur(7px);
}

.card:hover .img-content svg {
  fill: transparent;
}


body {
  background-color: #000000;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  overflow-x: hidden;
}

/* Add this CSS for zoom effect on testimonial images */
.client__card img {
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.client__card:hover img {
  -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
          transform: scale(1.2);
}



.header__container {
  height: 500px; /* Adjust the height as needed */
  overflow: hidden;
}

.header__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}








.buttonn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(20, 20, 20);
  border: none;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-shadow: 0px 0px 0px 4px rgba(180, 160, 255, 0.253);
          box-shadow: 0px 0px 0px 4px rgba(180, 160, 255, 0.253);
  cursor: pointer;
  -webkit-transition-duration: 0.3s;
       -o-transition-duration: 0.3s;
          transition-duration: 0.3s;
  overflow: hidden;
  position: fixed;
  bottom: 20px;
  right: 20px; /* Adjust these values for the desired position */
}

.svgIcon {
  width: 12px;
  -webkit-transition-duration: 0.3s;
       -o-transition-duration: 0.3s;
          transition-duration: 0.3s;
}

.svgIcon path {
  fill: white;
}

.buttonn:hover {
  width: 140px;
  border-radius: 50px;
  -webkit-transition-duration: 0.3s;
       -o-transition-duration: 0.3s;
          transition-duration: 0.3s;
  background: -webkit-gradient(linear, left top, right top, from(#c2b3ea), color-stop(#99b8f1), color-stop(#6abceb), color-stop(#41bed8), color-stop(#3abdbb), color-stop(#44c1ac), color-stop(#59c39a), color-stop(#72c486), color-stop(#83cd7f), color-stop(#96d676), color-stop(#acde6c), to(#c5e562));
  background: -o-linear-gradient(left, #c2b3ea, #99b8f1, #6abceb, #41bed8, #3abdbb, #44c1ac, #59c39a, #72c486, #83cd7f, #96d676, #acde6c, #c5e562);
  background: linear-gradient(to right, #c2b3ea, #99b8f1, #6abceb, #41bed8, #3abdbb, #44c1ac, #59c39a, #72c486, #83cd7f, #96d676, #acde6c, #c5e562);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.buttonn:hover .svgIcon {
  -webkit-transition-duration: 0.3s;
       -o-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transform: translateY(-200%);
      -ms-transform: translateY(-200%);
          transform: translateY(-200%);
}

.buttonn::before {
  position: absolute;
  bottom: -20px;
  content: "Back to Top";
  color: white;
  font-size: 0px;
}

.buttonn:hover::before {
  font-size: 13px;
  opacity: 1;
  bottom: unset;
  -webkit-transition-duration: 0.3s;
       -o-transition-duration: 0.3s;
          transition-duration: 0.3s;
}


/* Add these styles to your existing stylesheet */

.form-group label {
  color: #333; /* Set text color */
  font-size: 16px; /* Adjust font size */
  margin-bottom: 5px; /* Add margin for spacing */
}

.form-group input[type="text"],
.form-group textarea,
.form-group input[type="file"] {
  width: 100%; /* Make the input fields and file input span the full width */
  padding: 10px; /* Add padding for better appearance */
  margin-bottom: 15px; /* Add margin for spacing between elements */
  -webkit-box-sizing: border-box;
          box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.form-group input[type="file"] {
  background-color: #333; /* Set background color to a dark color */
  color: #fff; /* Set text color to white */
  padding: 10px; /* Add padding for better appearance */
  border: none; /* Remove default border */
  border-radius: 5px; /* Add border-radius for rounded corners */
  cursor: pointer; /* Change cursor to pointer on hover */
}

.form-group input[type="file"]:hover {
  background-color: #555; /* Darken background color on hover */
}








 /* Add these styles to your existing stylesheet */

 .form-group label {
  color: #fff; /* Set text color to white */
}

.form-group input[type="file"] {
  display: none; /* Hide the default file input */
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn {
  border: 2px solid gray;
  color: gray;
  background-color: #fff;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.upload-btn-wrapper:hover .btn {
  border-color: #333;
  color: #333;
}

/* Style the upload button on hover for better user interaction */
.form-group input[type="file"]:hover + .upload-btn-wrapper .btn {
  border-color: #333;
  color: #333;
}




.form-container .form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 2px;
}
body {
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #ddd transparent;
   
}

        body::-webkit-scrollbar {
            width: 12px;
        }

        body::-webkit-scrollbar-thumb {
          background: -webkit-gradient(linear, left top, left bottom, from(#c2b3ea), color-stop(#99b8f1), color-stop(#6abceb), color-stop(#41bed8), color-stop(#3abdbb), color-stop(#44c1ac), color-stop(#59c39a), color-stop(#72c486), color-stop(#83cd7f), color-stop(#96d676), color-stop(#acde6c), to(#c5e562));
          background: linear-gradient(to bottom, #c2b3ea, #99b8f1, #6abceb, #41bed8, #3abdbb, #44c1ac, #59c39a, #72c486, #83cd7f, #96d676, #acde6c, #c5e562);
      
        }

        body::-webkit-scrollbar-track {
            background-color: transparent;
        }

        #scroll-progress-container {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 5px;
            background-color: #000000;
            z-index: 1000;
            display: none; /* Hide initially */
        }

        #scroll-progress-bar {
            height: 0;
            width: 0;
            background: -webkit-gradient(linear, left top, right top, from(#c2b3ea), color-stop(#99b8f1), color-stop(#6abceb), color-stop(#41bed8), color-stop(#3abdbb), color-stop(#44c1ac), color-stop(#59c39a), color-stop(#72c486), color-stop(#83cd7f), color-stop(#96d676), color-stop(#acde6c), to(#c5e562));
            background: -o-linear-gradient(left, #c2b3ea, #99b8f1, #6abceb, #41bed8, #3abdbb, #44c1ac, #59c39a, #72c486, #83cd7f, #96d676, #acde6c, #c5e562);
            background: linear-gradient(to right, #c2b3ea, #99b8f1, #6abceb, #41bed8, #3abdbb, #44c1ac, #59c39a, #72c486, #83cd7f, #96d676, #acde6c, #c5e562);
            -webkit-transition: width 0.2s, height 0.2s;
            -o-transition: width 0.2s, height 0.2s;
            transition: width 0.2s, height 0.2s;
        }








.client__card img {
    margin-bottom: 1rem;
    /* max-width: 66px; */
    width: 62px;
    border: 2px solid #ffffff;
    border-radius: 100%;
    max-height: 65px;
}


.nav__logo-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.nav__logo-img {
  width: 50px;
  height: auto;
  margin-right: 10px;
  -webkit-transition: margin-right .3s ease-out;
  -o-transition: margin-right .3s ease-out;
  transition: margin-right .3s ease-out;
}

.nav__logo {
  background: -webkit-gradient(
      linear,
      left top, right top,
      from(#c2b3ea),
      color-stop(#99b8f1),
      color-stop(#6abceb),
      color-stop(#41bed8),
      color-stop(#3abdbb),
      color-stop(#44c1ac),
      color-stop(#59c39a),
      color-stop(#72c486),
      color-stop(#83cd7f),
      color-stop(#96d676),
      color-stop(#acde6c),
      to(#c5e562)
  );
  background: -o-linear-gradient(
      left,
      #c2b3ea,
      #99b8f1,
      #6abceb,
      #41bed8,
      #3abdbb,
      #44c1ac,
      #59c39a,
      #72c486,
      #83cd7f,
      #96d676,
      #acde6c,
      #c5e562
  );
  background: linear-gradient(
      to right,
      #c2b3ea,
      #99b8f1,
      #6abceb,
      #41bed8,
      #3abdbb,
      #44c1ac,
      #59c39a,
      #72c486,
      #83cd7f,
      #96d676,
      #acde6c,
      #c5e562
  );
  -webkit-background-clip: text;
  color: transparent;
  -webkit-animation: textAnimation 5s infinite alternate;
          animation: textAnimation 5s infinite alternate;
}

@-webkit-keyframes textAnimation {
  0% {
      color: #c2b3ea;
  }

  25% {
      color: #3abdbb;
  }

  50% {
      color: #59c39a;
  }

  75% {
      color: #83cd7f;
  }

  100% {
      color: #c5e562;
  }
}

@keyframes textAnimation {
  0% {
      color: #c2b3ea;
  }

  25% {
      color: #3abdbb;
  }

  50% {
      color: #59c39a;
  }

  75% {
      color: #83cd7f;
  }

  100% {
      color: #c5e562;
  }
}



















.nav__container.scrolled .nav__logo-container {
  position: fixed;
  left: -7%;
  top: 50%; /* Adjust the vertical position */
  -webkit-transform: rotate(90deg) translateX(-45px) translateY(-50%);
      -ms-transform: rotate(90deg) translateX(-45px) translateY(-50%);
          transform: rotate(90deg) translateX(-45px) translateY(-50%); /* Center vertically */
  height: 45px;
  -webkit-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.nav__logo-img.scrolled {
  margin-right: 10%; /* Adjust the margin as needed or set it to a negative value to move the logo to the left */
}



@media (max-width: 767px) {

  .nav__container.scrolled .nav__logo-container {
    position: fixed;
    left: -35%;
    top: 50%; /* Adjust the vertical position */
    -webkit-transform: rotate(90deg) translateX(-45px) translateY(-50%);
        -ms-transform: rotate(90deg) translateX(-45px) translateY(-50%);
            transform: rotate(90deg) translateX(-45px) translateY(-50%); /* Center vertically */
    height: 45px;
    -webkit-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out;
  }
  
  }



  @media (max-width: 820px) {

    .description1{
      line-height: 1.8;
      width: 34vh;
  }
    
  .col-md-6 {
    margin-left: 30%;
    margin-right: 0%;
    margin-top: 10%;
    padding-left: 0px;
}
    }
  


  @media (max-width: 1024px) {

    .nav__container.scrolled .nav__logo-container {
      position: fixed;
      left: -12%;
      top: 50%; /* Adjust the vertical position */
      -webkit-transform: rotate(90deg) translateX(-45px) translateY(-50%);
          -ms-transform: rotate(90deg) translateX(-45px) translateY(-50%);
              transform: rotate(90deg) translateX(-45px) translateY(-50%); /* Center vertically */
      height: 45px;
      -webkit-transition: all .3s ease-out;
      -o-transition: all .3s ease-out;
      transition: all .3s ease-out;
    }
    }





    @media (min-width: 768px) {
      .nav__container.scrolled .nav__logo-container {
        position: fixed;
        left: -7%;
        top: 50%;
        -webkit-transform: rotate(90deg) translateX(-45px) translateY(-50%);
            -ms-transform: rotate(90deg) translateX(-45px) translateY(-50%);
                transform: rotate(90deg) translateX(-45px) translateY(-50%);
        height: 45px;
        -webkit-transition: all .3s ease-out;
        -o-transition: all .3s ease-out;
        transition: all .3s ease-out;
      }
    
      .nav__logo-img.scrolled {
        margin-right: 10%;
      }
    }






    @media (max-width: 1024px)
    {
.nav__container.scrolled .nav__logo-container {
    position: absolute;
    /* left: -12%; */
    /* top: 50%; */
    /* transform: rotate(90deg) translateX(-45px) translateY(-50%); */
    /* height: 45px; */
    /* -webkit-transition: all .3s ease-out; */
    /* transition: all .3s ease-out; */
}
    }
@media (max-width: 767px)

{
.nav__container.scrolled .nav__logo-container {
    position: absolute;
     left: 0; 
    top: 0; 
    -webkit-transform: none; 
        -ms-transform: none; 
            transform: none; 
    height: 0; 
    -webkit-transition: none; 
     -o-transition: none; 
     transition: none; 
}



@media (max-width: 767px) {
  .button {
    position: fixed;
    /* bottom: 10px;  */
    right: 10px; /* Adjust the position as needed */
    z-index: 999; /* Adjust the z-index as needed */
  }
}















}







.footer__col a {
  text-decoration: none;
color: aliceblue;
}














.ss{
  color:aliceblue;
}

    
ul.timeline {
  list-style-type: none;
  position: relative;
 
}
ul.timeline:before {
  content: ' ';
  background: -webkit-gradient(
linear,
left top, left bottom,
from(#c2b3ea),
color-stop(#99b8f1),
color-stop(#6abceb),
color-stop(#41bed8),
color-stop(#3abdbb),
color-stop(#44c1ac),
color-stop(#59c39a),
color-stop(#72c486),
color-stop(#83cd7f),
color-stop(#96d676),
color-stop(#acde6c),
to(#c5e562)
);
  background: -o-linear-gradient(
top,
#c2b3ea,
#99b8f1,
#6abceb,
#41bed8,
#3abdbb,
#44c1ac,
#59c39a,
#72c486,
#83cd7f,
#96d676,
#acde6c,
#c5e562
);
  background: linear-gradient(
to bottom,
#c2b3ea,
#99b8f1,
#6abceb,
#41bed8,
#3abdbb,
#44c1ac,
#59c39a,
#72c486,
#83cd7f,
#96d676,
#acde6c,
#c5e562
);
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
  -webkit-transition: height 0.5s ease;
  -o-transition: height 0.5s ease;
  transition: height 0.5s ease; /* Smooth transition for the line height */
}

ul.timeline > li {
  margin: 143px  0;
  padding-left: 20px;
  opacity: 0; /* Initially hide the text */
  -webkit-transform: translateX(-20px);
      -ms-transform: translateX(-20px);
          transform: translateX(-20px); /* Move the text to the left */
  -webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  -o-transition: opacity 0.5s ease, transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease; /* Smooth transition for text visibility */
}

ul.timeline > li.active {
  opacity: 1; /* Make the text visible */
  -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
          transform: translateX(0); /* Move the text to its original position */
}

ul.timeline > li:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e8;
  left: 12px; /* Adjust the left position */
  width: 20px;
  height: 20px;
  z-index: 400;
  -webkit-transition: background-color 0.5s ease, left 0.5s ease;
  -o-transition: background-color 0.5s ease, left 0.5s ease;
  transition: background-color 0.5s ease, left 0.5s ease; /* Smooth transition for dot color and position */
}

ul.timeline > li.active:before {
  background-color: #000000; /* Change dot color when active */
  left: 18px; /* Adjust the left position when active */
} 



.col-md-6  {
margin-left: 30%;
margin-right: 30%;
margin-top: 10%;
padding-left: 50px;
}


@media (max-width: 767px) {

.col-md-6  {
margin: 0%;
padding: 10px;
}
}

.pp{

  padding-left: 50px;
}

.ppp{
  padding-left: 50px;
  font-size: 1.1rem;
  color: var(--secondary-color);


}

.news{
  font-size: 2.5rem;
    font-weight: 600;
    line-height: 3rem;
    text-align: center;
    background: -webkit-gradient( linear, left top, right top, from(#c2b3ea), color-stop(#99b8f1), color-stop(#6abceb), color-stop(#41bed8), color-stop(#3abdbb), color-stop(#44c1ac), color-stop(#59c39a), color-stop(#72c486), color-stop(#83cd7f), color-stop(#96d676), color-stop(#acde6c), to(#c5e562) );
    background: -o-linear-gradient( left, #c2b3ea, #99b8f1, #6abceb, #41bed8, #3abdbb, #44c1ac, #59c39a, #72c486, #83cd7f, #96d676, #acde6c, #c5e562 );
    background: linear-gradient( to right, #c2b3ea, #99b8f1, #6abceb, #41bed8, #3abdbb, #44c1ac, #59c39a, #72c486, #83cd7f, #96d676, #acde6c, #c5e562 );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}


















.cardaa{
  overflow: hidden;
  position: relative;
  width: 340px;
  min-height: 400px;
  color:aliceblue;
  background: #000000;
  -webkit-box-shadow: -10px 15px 80px rgba(76, 76, 76, 0.15);
          box-shadow: -10px 15px 80px rgba(76, 76, 76, 0.15);
  padding: 20px;
  margin-left: 58.5%;
}


@media (max-width: 767px) {

  .cardaa{

    margin-left: 10%;
  }
  }
  







.cardaa .topaa{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.cardaa .topaa .userDeatilsaa{
  padding-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.cardaa .topaa .userDeatilsaa .profileImgaa{
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 8px;
  overflow: hidden;
}

.coveraa{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  cursor: pointer;
    color:aliceblue;
}

.cardaa .topaa .userDeatilsaa h3{
  font-size: 18px;
  color: aliceblue;;
  font-weight: 300;
  line-height: 1rem;
  cursor: pointer;
}

.cardaa .topaa .userDeatilsaa span{
  font-size: 0.75em;
}

.cardaa .topaa .dotaa{
  -webkit-transform: scale(0.6);
      -ms-transform: scale(0.6);
          transform: scale(0.6);
  cursor: pointer;
}

.imgBgaa{
  position: relative;
  width: 100%;
  height: 320px;
  margin: 10px 0 15px;
}

.btnsaa{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.btnsaa img{
  max-width: 24px;
  cursor: pointer;
}

.btnsaa .leftaa img{
  margin-right: 8px;
}

.likesaa{
  margin-top: 5px;
  font-size: 1em;
  color: #4d4d4f;
}

.messageaa{
  font-weight: 400;
  margin-top: 5px;
  color: #777;
  line-height: 1.5em;
}

.messageaa b{
  color: #262626;
}

.messageaa span{
  color: #1d92ff;
  cursor: pointer;
}

.commentsaa{
  margin-top: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #999;
}

.addCommentsaa{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 10px;
}

.addCommentsaa .userImgaa{
  position: relative;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 8px;
}

.addCommentsaa .textaa{
  width: 100%;
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 18px;
  color: #262626;
}

.addCommentsaa .textaa::-webkit-input-placeholder{
  color: #777;
}

.addCommentsaa .textaa::-moz-placeholder{
  color: #777;
}

.addCommentsaa .textaa:-ms-input-placeholder{
  color: #777;
}

.addCommentsaa .textaa::-ms-input-placeholder{
  color: #777;
}

.addCommentsaa .textaa::placeholder{
  color: #777;
}

.postTimeaa{
  margin-top: 10px;
  font-weight: 500;
  color: #777;
}


.imgBgaa {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 300%; /* Set the width to accommodate multiple images */
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease; /* Add a smooth transition effect */
}

.bgImage {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}


.dotContainer {
  position: absolute;
  
  bottom: 35%;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #bbb; /* Inactive dot color */
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: #333; /* Active dot color */
}



.description-container {
  position: absolute;
  padding-left: 10px;
  margin-right: 30%;
  width: 60%;
  margin-left: 10%;
  padding-right: 353px;
  margin-top: 7%;
}


.description1 {
  color: aliceblue;
}












.Btn {
  width: 45px;
  height: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: none;
  background-color: transparent;
  position: relative;
  /* overflow: hidden; */
  border-radius: 7px;
  cursor: pointer;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.svgContainer {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: transparent;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  letter-spacing: 0.8px;
  border-radius: 10px;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  border: 1px solid rgba(156, 156, 156, 0.466);
}

.BG {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #f09433;
  background: -o-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  z-index: -1;
  border-radius: 9px;
  pointer-events: none;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.Btn:hover .BG {
  -webkit-transform: rotate(35deg);
      -ms-transform: rotate(35deg);
          transform: rotate(35deg);
  -webkit-transform-origin: bottom;
      -ms-transform-origin: bottom;
          transform-origin: bottom;
}

.Btn:hover .svgContainer {
  background-color: rgba(156, 156, 156, 0.466);
}



.follow{


  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  color: var(--secondary-color);
  text-align: center;

}

@media screen and (max-width: 600px) {
  .description-container {
    position: relative; /* Change from absolute to relative positioning */
    width: 100%; /* Set width to 100% to occupy the full width */
    margin: 0; /* Remove margin to align with the card */
    padding: 10px; /* Add padding for spacing */
    text-align: center;
  }

  .description1 {
    margin-left: 5.5vh;
    line-height: 1.5; /* Adjust line-height for better readability on smaller screens */
  }

  .cardaa{
    padding-bottom: 100px;
  }
  .follow{
    padding-top: 50px;
  }

  .Btn {
    width: 45px;
    height: 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border: none;
    background-color: transparent;
    position: relative;
    /* overflow: hidden; */
    border-radius: 7px;
    cursor: pointer;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    margin-left: 213px;
    margin-top: -42px;
}

.follow {
  margin-right: 80px;
  padding-top: 50px;
}
}



.buttonn {
  position: fixed;
  bottom: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  z-index: 999; /* Ensure it's on top of other elements */
  
  color: white; /* Example text color */
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}


@media only screen and (max-width: 600px)

{

  .form-container {
    width: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: absolute;
    right: -10px;
    margin-top: 474px;
}

.quest{
  margin-top: 300px;
}



.col-md-6 {
  margin-left: 30%;
  margin-right: 0%;
  margin-top: 10%;
  padding-left: 0px;
}
ul.timeline {
  list-style-type: none;
  position: relative;
  margin-left: -9vh;
}


}


.imgBgaa {
  position: relative;
  width: 100%; /* Adjust as needed */
  height: 300px; /* Set the desired height for your card container */
  overflow: hidden;
}

/* Style for the images */
.bgImage {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover; /* or "contain" depending on your preference */
  -webkit-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease; /* Add a smooth transition effect */
}



.serv{

  padding-top: 50px;


}






.wheel-and-hamster {
  --dur: 1s;
  position: relative;
  width: 12em;
  height: 12em;
  font-size: 6px;
  margin-left: 35%;
}

.wheel,
.hamster,
.hamster div,
.spoke {
  position: absolute;
}

.wheel,
.spoke {
  border-radius: 50%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.wheel {
  background: -o-radial-gradient(center,100% 100%,hsla(0,0%,60%,0) 47.8%,hsl(0,0%,60%) 48%);
  background: radial-gradient(100% 100% at center,hsla(0,0%,60%,0) 47.8%,hsl(0,0%,60%) 48%);
  z-index: 2;
}

.hamster {
  -webkit-animation: hamster var(--dur) ease-in-out infinite;
          animation: hamster var(--dur) ease-in-out infinite;
  top: 50%;
  left: calc(50% - 3.5em);
  width: 7em;
  height: 3.75em;
  -webkit-transform: rotate(4deg) translate(-0.8em,1.85em);
      -ms-transform: rotate(4deg) translate(-0.8em,1.85em);
          transform: rotate(4deg) translate(-0.8em,1.85em);
  -webkit-transform-origin: 50% 0;
      -ms-transform-origin: 50% 0;
          transform-origin: 50% 0;
  z-index: 1;
}

.hamster__head {
  -webkit-animation: hamsterHead var(--dur) ease-in-out infinite;
          animation: hamsterHead var(--dur) ease-in-out infinite;
  background: hsl(30,90%,55%);
  border-radius: 70% 30% 0 100% / 40% 25% 25% 60%;
  -webkit-box-shadow: 0 -0.25em 0 hsl(30,90%,80%) inset,
		0.75em -1.55em 0 hsl(30,90%,90%) inset;
          box-shadow: 0 -0.25em 0 hsl(30,90%,80%) inset,
		0.75em -1.55em 0 hsl(30,90%,90%) inset;
  top: 0;
  left: -2em;
  width: 2.75em;
  height: 2.5em;
  -webkit-transform-origin: 100% 50%;
      -ms-transform-origin: 100% 50%;
          transform-origin: 100% 50%;
}

.hamster__ear {
  -webkit-animation: hamsterEar var(--dur) ease-in-out infinite;
          animation: hamsterEar var(--dur) ease-in-out infinite;
  background: hsl(0,90%,85%);
  border-radius: 50%;
  -webkit-box-shadow: -0.25em 0 hsl(30,90%,55%) inset;
          box-shadow: -0.25em 0 hsl(30,90%,55%) inset;
  top: -0.25em;
  right: -0.25em;
  width: 0.75em;
  height: 0.75em;
  -webkit-transform-origin: 50% 75%;
      -ms-transform-origin: 50% 75%;
          transform-origin: 50% 75%;
}

.hamster__eye {
  -webkit-animation: hamsterEye var(--dur) linear infinite;
          animation: hamsterEye var(--dur) linear infinite;
  background-color: hsl(0,0%,0%);
  border-radius: 50%;
  top: 0.375em;
  left: 1.25em;
  width: 0.5em;
  height: 0.5em;
}

.hamster__nose {
  background: hsl(0,90%,75%);
  border-radius: 35% 65% 85% 15% / 70% 50% 50% 30%;
  top: 0.75em;
  left: 0;
  width: 0.2em;
  height: 0.25em;
}

.hamster__body {
  -webkit-animation: hamsterBody var(--dur) ease-in-out infinite;
          animation: hamsterBody var(--dur) ease-in-out infinite;
  background: hsl(30,90%,90%);
  border-radius: 50% 30% 50% 30% / 15% 60% 40% 40%;
  -webkit-box-shadow: 0.1em 0.75em 0 hsl(30,90%,55%) inset,
		0.15em -0.5em 0 hsl(30,90%,80%) inset;
          box-shadow: 0.1em 0.75em 0 hsl(30,90%,55%) inset,
		0.15em -0.5em 0 hsl(30,90%,80%) inset;
  top: 0.25em;
  left: 2em;
  width: 4.5em;
  height: 3em;
  -webkit-transform-origin: 17% 50%;
      -ms-transform-origin: 17% 50%;
          transform-origin: 17% 50%;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.hamster__limb--fr,
.hamster__limb--fl {
  -webkit-clip-path: polygon(0 0,100% 0,70% 80%,60% 100%,0% 100%,40% 80%);
          clip-path: polygon(0 0,100% 0,70% 80%,60% 100%,0% 100%,40% 80%);
  top: 2em;
  left: 0.5em;
  width: 1em;
  height: 1.5em;
  -webkit-transform-origin: 50% 0;
      -ms-transform-origin: 50% 0;
          transform-origin: 50% 0;
}

.hamster__limb--fr {
  -webkit-animation: hamsterFRLimb var(--dur) linear infinite;
          animation: hamsterFRLimb var(--dur) linear infinite;
  background: -webkit-gradient(linear,left top, left bottom,color-stop(80%, hsl(30,90%,80%)),color-stop(80%, hsl(0,90%,75%)));
  background: -o-linear-gradient(hsl(30,90%,80%) 80%,hsl(0,90%,75%) 80%);
  background: linear-gradient(hsl(30,90%,80%) 80%,hsl(0,90%,75%) 80%);
  -webkit-transform: rotate(15deg) translateZ(-1px);
          transform: rotate(15deg) translateZ(-1px);
}

.hamster__limb--fl {
  -webkit-animation: hamsterFLLimb var(--dur) linear infinite;
          animation: hamsterFLLimb var(--dur) linear infinite;
  background: -webkit-gradient(linear,left top, left bottom,color-stop(80%, hsl(30,90%,90%)),color-stop(80%, hsl(0,90%,85%)));
  background: -o-linear-gradient(hsl(30,90%,90%) 80%,hsl(0,90%,85%) 80%);
  background: linear-gradient(hsl(30,90%,90%) 80%,hsl(0,90%,85%) 80%);
  -webkit-transform: rotate(15deg);
      -ms-transform: rotate(15deg);
          transform: rotate(15deg);
}

.hamster__limb--br,
.hamster__limb--bl {
  border-radius: 0.75em 0.75em 0 0;
  -webkit-clip-path: polygon(0 0,100% 0,100% 30%,70% 90%,70% 100%,30% 100%,40% 90%,0% 30%);
          clip-path: polygon(0 0,100% 0,100% 30%,70% 90%,70% 100%,30% 100%,40% 90%,0% 30%);
  top: 1em;
  left: 2.8em;
  width: 1.5em;
  height: 2.5em;
  -webkit-transform-origin: 50% 30%;
      -ms-transform-origin: 50% 30%;
          transform-origin: 50% 30%;
}

.hamster__limb--br {
  -webkit-animation: hamsterBRLimb var(--dur) linear infinite;
          animation: hamsterBRLimb var(--dur) linear infinite;
  background: -webkit-gradient(linear,left top, left bottom,color-stop(90%, hsl(30,90%,80%)),color-stop(90%, hsl(0,90%,75%)));
  background: -o-linear-gradient(hsl(30,90%,80%) 90%,hsl(0,90%,75%) 90%);
  background: linear-gradient(hsl(30,90%,80%) 90%,hsl(0,90%,75%) 90%);
  -webkit-transform: rotate(-25deg) translateZ(-1px);
          transform: rotate(-25deg) translateZ(-1px);
}

.hamster__limb--bl {
  -webkit-animation: hamsterBLLimb var(--dur) linear infinite;
          animation: hamsterBLLimb var(--dur) linear infinite;
  background: -webkit-gradient(linear,left top, left bottom,color-stop(90%, hsl(30,90%,90%)),color-stop(90%, hsl(0,90%,85%)));
  background: -o-linear-gradient(hsl(30,90%,90%) 90%,hsl(0,90%,85%) 90%);
  background: linear-gradient(hsl(30,90%,90%) 90%,hsl(0,90%,85%) 90%);
  -webkit-transform: rotate(-25deg);
      -ms-transform: rotate(-25deg);
          transform: rotate(-25deg);
}

.hamster__tail {
  -webkit-animation: hamsterTail var(--dur) linear infinite;
          animation: hamsterTail var(--dur) linear infinite;
  background: hsl(0,90%,85%);
  border-radius: 0.25em 50% 50% 0.25em;
  -webkit-box-shadow: 0 -0.2em 0 hsl(0,90%,75%) inset;
          box-shadow: 0 -0.2em 0 hsl(0,90%,75%) inset;
  top: 1.5em;
  right: -0.5em;
  width: 1em;
  height: 0.5em;
  -webkit-transform: rotate(30deg) translateZ(-1px);
          transform: rotate(30deg) translateZ(-1px);
  -webkit-transform-origin: 0.25em 0.25em;
      -ms-transform-origin: 0.25em 0.25em;
          transform-origin: 0.25em 0.25em;
}

.spoke {
  -webkit-animation: spoke var(--dur) linear infinite;
          animation: spoke var(--dur) linear infinite;
  background: radial-gradient(100% 100% at center,hsl(0,0%,60%) 4.8%,hsla(0,0%,60%,0) 5%),
		-webkit-gradient(linear,left top, left bottom,color-stop(46.9%, hsla(0,0%,55%,0)),color-stop(47%, hsl(0,0%,65%)),color-stop(53%, hsla(0,0%,65%,0))) 50% 50% / 99% 99% no-repeat;
  background: -o-radial-gradient(center,100% 100%,hsl(0,0%,60%) 4.8%,hsla(0,0%,60%,0) 5%),
		-o-linear-gradient(hsla(0,0%,55%,0) 46.9%,hsl(0,0%,65%) 47% 52.9%,hsla(0,0%,65%,0) 53%) 50% 50% / 99% 99% no-repeat;
  background: radial-gradient(100% 100% at center,hsl(0,0%,60%) 4.8%,hsla(0,0%,60%,0) 5%),
		linear-gradient(hsla(0,0%,55%,0) 46.9%,hsl(0,0%,65%) 47% 52.9%,hsla(0,0%,65%,0) 53%) 50% 50% / 99% 99% no-repeat;
}

/* Animations */
@-webkit-keyframes hamster {
  from, to {
    -webkit-transform: rotate(4deg) translate(-0.8em,1.85em);
            transform: rotate(4deg) translate(-0.8em,1.85em);
  }

  50% {
    -webkit-transform: rotate(0) translate(-0.8em,1.85em);
            transform: rotate(0) translate(-0.8em,1.85em);
  }
}
@keyframes hamster {
  from, to {
    -webkit-transform: rotate(4deg) translate(-0.8em,1.85em);
            transform: rotate(4deg) translate(-0.8em,1.85em);
  }

  50% {
    -webkit-transform: rotate(0) translate(-0.8em,1.85em);
            transform: rotate(0) translate(-0.8em,1.85em);
  }
}

@-webkit-keyframes hamsterHead {
  from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }

  12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(8deg);
            transform: rotate(8deg);
  }
}

@keyframes hamsterHead {
  from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }

  12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(8deg);
            transform: rotate(8deg);
  }
}

@-webkit-keyframes hamsterEye {
  from, 90%, to {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }

  95% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
}

@keyframes hamsterEye {
  from, 90%, to {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }

  95% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
}

@-webkit-keyframes hamsterEar {
  from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }

  12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(12deg);
            transform: rotate(12deg);
  }
}

@keyframes hamsterEar {
  from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }

  12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(12deg);
            transform: rotate(12deg);
  }
}

@-webkit-keyframes hamsterBody {
  from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }

  12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
}

@keyframes hamsterBody {
  from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }

  12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
}

@-webkit-keyframes hamsterFRLimb {
  from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(50deg) translateZ(-1px);
            transform: rotate(50deg) translateZ(-1px);
  }

  12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(-30deg) translateZ(-1px);
            transform: rotate(-30deg) translateZ(-1px);
  }
}

@keyframes hamsterFRLimb {
  from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(50deg) translateZ(-1px);
            transform: rotate(50deg) translateZ(-1px);
  }

  12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(-30deg) translateZ(-1px);
            transform: rotate(-30deg) translateZ(-1px);
  }
}

@-webkit-keyframes hamsterFLLimb {
  from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }

  12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(50deg);
            transform: rotate(50deg);
  }
}

@keyframes hamsterFLLimb {
  from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }

  12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(50deg);
            transform: rotate(50deg);
  }
}

@-webkit-keyframes hamsterBRLimb {
  from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(-60deg) translateZ(-1px);
            transform: rotate(-60deg) translateZ(-1px);
  }

  12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(20deg) translateZ(-1px);
            transform: rotate(20deg) translateZ(-1px);
  }
}

@keyframes hamsterBRLimb {
  from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(-60deg) translateZ(-1px);
            transform: rotate(-60deg) translateZ(-1px);
  }

  12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(20deg) translateZ(-1px);
            transform: rotate(20deg) translateZ(-1px);
  }
}

@-webkit-keyframes hamsterBLLimb {
  from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
  }

  12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(-60deg);
            transform: rotate(-60deg);
  }
}

@keyframes hamsterBLLimb {
  from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
  }

  12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(-60deg);
            transform: rotate(-60deg);
  }
}

@-webkit-keyframes hamsterTail {
  from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(30deg) translateZ(-1px);
            transform: rotate(30deg) translateZ(-1px);
  }

  12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(10deg) translateZ(-1px);
            transform: rotate(10deg) translateZ(-1px);
  }
}

@keyframes hamsterTail {
  from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(30deg) translateZ(-1px);
            transform: rotate(30deg) translateZ(-1px);
  }

  12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(10deg) translateZ(-1px);
            transform: rotate(10deg) translateZ(-1px);
  }
}

@-webkit-keyframes spoke {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(-1turn);
            transform: rotate(-1turn);
  }
}

@keyframes spoke {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(-1turn);
            transform: rotate(-1turn);
  }
}

















.tooltip{
position: absolute;
  margin-left: -160px;
}



.item-hints {
  --purple: #720c8f;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding-right: 170px;
}

.item-hints .hint {
  margin: 86px auto;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.item-hints .hint-dot {
  z-index: 3;
  border: 1px solid #ffe4e4;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-transform: translate(-0%, -0%) scale(0.95);
  -ms-transform: translate(-0%, -0%) scale(0.95);
      transform: translate(-0%, -0%) scale(0.95);
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: white;
}
.item-hints .hint-radius {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -125px 0 0 -125px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
      transform: scale(0);
}
.item-hints .hint[data-position="1"] .hint-content {
  top: 85px;
  left: 50%;
  margin-left: 56px;
}
.item-hints .hint-content {
  width: 300px;
  position: absolute;
  z-index: 5;
  padding: 35px 0;
  opacity: 0;
  -webkit-transition: opacity 0.7s ease, visibility 0.7s ease;
  -o-transition: opacity 0.7s ease, visibility 0.7s ease;
  transition: opacity 0.7s ease, visibility 0.7s ease;
  color: #fff;
  visibility: hidden;
  pointer-events: none;
}
.item-hints .hint:hover .hint-content {
  position: absolute;
  z-index: 5;
  padding: 35px 0;
  opacity: 1;
  -webkit-transition: opacity 0.7s ease, visibility 0.7s ease;
  -o-transition: opacity 0.7s ease, visibility 0.7s ease;
  transition: opacity 0.7s ease, visibility 0.7s ease;
  color: #fff;
  visibility: visible;
  pointer-events: none;
}
.item-hints .hint-content::before {
  width: 0px;
  bottom: 29px;
  left: 0;
  content: "";
  background-color: #fff;
  height: 1px;
  position: absolute;
  -webkit-transition: width 0.4s;
  -o-transition: width 0.4s;
  transition: width 0.4s;
}
.item-hints .hint:hover .hint-content::before {
  width: 180px;
  -webkit-transition: width 0.4s;
  -o-transition: width 0.4s;
  transition: width 0.4s;
}
.item-hints .hint-content::after {
  -webkit-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
      transform-origin: 0 50%;
  -webkit-transform: rotate(-225deg);
  -ms-transform: rotate(-225deg);
      transform: rotate(-225deg);
  bottom: 29px;
  left: 0;
  width: 80px;
  content: "";
  background-color: #fff;
  height: 1px;
  position: absolute;
  opacity: 1;
  -webkit-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
     transition-delay: 0s;
}
.item-hints .hint:hover .hint-content::after {
  opacity: 1;
  visibility: visible;
}
.item-hints .hint[data-position="4"] .hint-content {
  bottom: 85px;
  left: 50%;
  margin-left: 56px;
}


















































.grow, .grow-small{
  -webkit-transform: scale(7);
      -ms-transform: scale(7);
          transform: scale(7);
  background: #fff;
  mix-blend-mode: difference;
  border: none;
}
.grow-small{
  -webkit-transform: scale(2.5);
      -ms-transform: scale(2.5);
          transform: scale(2.5);
}.cursor {
  position: fixed;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  margin-top: -20px;
  border-radius: 50%;
  border: 0px solid #16a085;
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  -o-transition: transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease;
  -webkit-transform-origin: center center;
      -ms-transform-origin: center center;
          transform-origin: center center;
  pointer-events: none;
  z-index: 1000;
}

.grow {
  -webkit-transform: scale(7);
      -ms-transform: scale(7);
          transform: scale(7);
  background: #ffffff;
  mix-blend-mode: difference;
  border: none;
 
}











.copyaa {
  /* button */
  --button-bg: #ffffff;
  --button-hover-bg: #464646;
  --button-text-color: #474747;
  --button-hover-text-color: #8bb9fe;
  --button-border-radius: 10px;
  --button-diameter: 36px;
  --button-outline-width: 1px;
  --button-outline-color: rgb(141, 141, 141);
  /* tooltip */
  --tooltip-bg: #f4f3f3;
  --toolptip-border-radius: 4px;
  --tooltip-font-family: Menlo, Roboto Mono, monospace;
  /* 👆 this field should not be empty */
  --tooltip-font-size: 12px;
  /* 👆 this field should not be empty */
  --tootip-text-color: rgb(50, 50, 50);
  --tooltip-padding-x: 7px;
  --tooltip-padding-y: 7px;
  --tooltip-offset: 8px;
  /* --tooltip-transition-duration: 0.3s; */
  /* 👆 if you need a transition, 
  just remove the comment,
  but I didn't like the transition :| */
}

.copyaa {
  box-sizing: border-box;
  width: var(--button-diameter);
  height: var(--button-diameter);
  border-radius: var(--button-border-radius);
  background-color: var(--button-bg);
  color: var(--button-text-color);
  border: none;
  cursor: pointer;
  position: relative;
  outline: none;
}

.tooltipaa {
  position: absolute;
  opacity: 0;
  visibility: 0;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  font: var(--tooltip-font-size) var(--tooltip-font-family);
  color: var(--tootip-text-color);
  background: var(--tooltip-bg);
  padding: var(--tooltip-padding-y) var(--tooltip-padding-x);
  border-radius: var(--toolptip-border-radius);
  pointer-events: none;
  transition: all var(--tooltip-transition-duration)
    cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.tooltipaa::before {
  content: attr(data-text-initial);
}

.tooltipaa::after {
  content: "";
  position: absolute;
  bottom: calc(var(--tooltip-padding-y) / 2 * -1);
  width: var(--tooltip-padding-y);
  height: var(--tooltip-padding-y);
  background: inherit;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  z-index: -999;
  pointer-events: none;
}

.copyaa svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.checkmarkaa {
  display: none;
}

/* actions */

.copyaa:hover .tooltipaa,
.copyaa:focus:not(:focus-visible) .tooltipaa {
  opacity: 1;
  visibility: visible;
  top: calc((100% + var(--tooltip-offset)) * -1);
}

.copyaa:focus:not(:focus-visible) .tooltipaa::before {
  content: attr(data-text-end);
}

.copyaa:focus:not(:focus-visible) .clipboardaa {
  display: none;
}

.copyaa:focus:not(:focus-visible) .checkmarkaa {
  display: block;
}

.copyaa:hover,
.copyaa:focus {
  background-color: var(--button-hover-bg);
}

.copyaa:active {
  outline: var(--button-outline-width) solid var(--button-outline-color);
}

.copyaa:hover svg {
  color: var(--button-hover-text-color);
}



.border-container {
  margin-top: 10rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 10px;
  display: flex;
  align-items: center;
  background-color: #1d1d1d;
}

.border-container p {
  margin: 0;
}


